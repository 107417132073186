<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Hotdeal</div>
    </div>
    <div class="page-container">
      <form @submit.prevent="onSubmit">
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Setting Hotdeal menu in Header &amp; Footer</h5>
            </div>
          </div>
          <hr />

          <div class="pb-3">
            <toggle-button
              v-model="form.hotdealEnabled"
              color="#0074d9"
              :sync="true"
              :labels="true"
            />
            Show / Hide "Hotdeal" menu on Header, Footer, and sitemap page (affected for all languages)
          </div>
          <b-form-group label="URL for Hotdeal menu on Header, Footer, and sitemap page (affected for all languages)">
            <b-form-input
              v-model="form.hotdealURL"
              type="text"
              placeholder="input full URL: https://abc.com"
            />
          </b-form-group>

          <b-btn size="sm" variant="primary" class="mx-1" type="submit">
            Update
          </b-btn>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import { HOT_DEAL_ENABLED, HOT_DEAL_URL } from '@/store/modules/config/getters'
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    uploadError: false,
    form: {
      hotdealEnabled: false,
      hotdealURL: ''
    }
  }),
  computed: {
    ...mapGetters('config', ['hotdealEnabled', 'hotdealURL'])
  },
  methods: {
    ...mapActions('config', ['getConfigs', 'updateConfigs']),
    onSubmit ($e) {
      $e.preventDefault()

      this.updateConfigs([
        { key: HOT_DEAL_ENABLED, value: this.form.hotdealEnabled ? 'true' : 'false' },
        { key: HOT_DEAL_URL, value: this.form.hotdealURL }
      ]).then(() => {
        this.$bvToast.toast('Update hotdeal successful', {
          title: 'Hotdeal updated',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      })
    }
  },
  async mounted () {
    const configs = await this.getConfigs()
    let config
    config = configs.find(({ key }) => key === HOT_DEAL_ENABLED)
    this.form.hotdealEnabled = config && config.value === 'true'

    config = configs.find(({ key }) => key === HOT_DEAL_URL)
    this.form.hotdealURL = config ? (config.value || '') : ''
  }
}
</script>
